import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { IdentityPageContext } from "../../helpers/pagesContext";
import { UNIVERS } from "../../helpers/route";
import { Carousel } from "./components/Carousel/Carousel";
import { Hero } from "./components/Hero/Hero";
import { Offices } from "./components/Offices/Offices";
import { Presentation } from "./components/Presentation/Presentation";
import { Statistics } from "./components/Statistics/Statistics";

export default function Identity() {
  const { footer, footerTitle, footerButtonLabel, node_locale } =
    useContext(IdentityPageContext);

  return (
    <>
      <Hero />
      <Presentation />
      <Carousel />
      <Offices />
      <Statistics />
      <OverFooter
        title={footerTitle}
        buttonLink={UNIVERS}
        buttonLabel={footerButtonLabel}
        image={footer.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </>
  );
}

// extracted by mini-css-extract-plugin
export var circleRootI = "Letters-module--circleRootI--d3328";
export var container = "Letters-module--container--d49c4";
export var drawingCircleI = "Letters-module--drawingCircleI--5062a";
export var drawingLeftA = "Letters-module--drawingLeftA--9a830";
export var drawingLeftAI = "Letters-module--drawingLeftAI--4c8e9";
export var drawingLeftC = "Letters-module--drawingLeftC--8ca8c";
export var drawingLeftE = "Letters-module--drawingLeftE--44f4d";
export var drawingLeftEI = "Letters-module--drawingLeftEI--904a1";
export var drawingLeftH = "Letters-module--drawingLeftH--0f290";
export var drawingLeftI = "Letters-module--drawingLeftI--e3de5";
export var drawingLeftL = "Letters-module--drawingLeftL--41c3f";
export var drawingLeftN = "Letters-module--drawingLeftN--25e15";
export var drawingLeftO = "Letters-module--drawingLeftO--2e7a2";
export var drawingLeftR = "Letters-module--drawingLeftR--30056";
export var drawingLeftS = "Letters-module--drawingLeftS--165d2";
export var drawingLeftT = "Letters-module--drawingLeftT--1fdf0";
export var drawingRightA = "Letters-module--drawingRightA--4254a";
export var drawingRightAI = "Letters-module--drawingRightAI--d46cd";
export var drawingRightC = "Letters-module--drawingRightC--752b9";
export var drawingRightE = "Letters-module--drawingRightE--5e3a9";
export var drawingRightEI = "Letters-module--drawingRightEI--292c7";
export var drawingRightH = "Letters-module--drawingRightH--cdc74";
export var drawingRightI = "Letters-module--drawingRightI--6246d";
export var drawingRightL = "Letters-module--drawingRightL--89db6";
export var drawingRightN = "Letters-module--drawingRightN--a6c67";
export var drawingRightO = "Letters-module--drawingRightO--2cbc6";
export var drawingRightR = "Letters-module--drawingRightR--6bf00";
export var drawingRightS = "Letters-module--drawingRightS--32188";
export var drawingRightT = "Letters-module--drawingRightT--94ac7";
export var leftRootA = "Letters-module--leftRootA--64821";
export var leftRootAI = "Letters-module--leftRootAI--20500";
export var leftRootC = "Letters-module--leftRootC--fbb7c";
export var leftRootE = "Letters-module--leftRootE--b85f2";
export var leftRootEI = "Letters-module--leftRootEI--d7e6c";
export var leftRootH = "Letters-module--leftRootH--7d05e";
export var leftRootI = "Letters-module--leftRootI--3673c";
export var leftRootL = "Letters-module--leftRootL--694c5";
export var leftRootN = "Letters-module--leftRootN--3fcd5";
export var leftRootO = "Letters-module--leftRootO--e1078";
export var leftRootR = "Letters-module--leftRootR--86088";
export var leftRootS = "Letters-module--leftRootS--89580";
export var leftRootT = "Letters-module--leftRootT--a8596";
export var rightRootA = "Letters-module--rightRootA--587cc";
export var rightRootAI = "Letters-module--rightRootAI--6e41d";
export var rightRootC = "Letters-module--rightRootC--db5e9";
export var rightRootE = "Letters-module--rightRootE--8d8f6";
export var rightRootEI = "Letters-module--rightRootEI--f4fc5";
export var rightRootH = "Letters-module--rightRootH--31315";
export var rightRootI = "Letters-module--rightRootI--c9ab0";
export var rightRootL = "Letters-module--rightRootL--a1d42";
export var rightRootN = "Letters-module--rightRootN--276f0";
export var rightRootO = "Letters-module--rightRootO--99742";
export var rightRootR = "Letters-module--rightRootR--707eb";
export var rightRootS = "Letters-module--rightRootS--42178";
export var rightRootT = "Letters-module--rightRootT--ca47a";
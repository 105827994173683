import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useRef } from "react";
import CountUp from "react-countup";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { IdentityPageContext } from "../../../../helpers/pagesContext";
import { useInView } from "../../../../helpers/useInView";
import * as styles from "./Statistics.module.css";

export const Statistics = () => {
  const {
    statistics,
    creation,
    missions,
    talents,
    statisticText,
    field,
    statisticsText,
    bpiLabel,
    bpiLogo,
  } = useContext(IdentityPageContext);

  const backgroundImage = statistics.find(
    (item) => item.title === "identity-statistics-background",
  );
  const iconImage = statistics.find(
    (item) => item.title === "identity-statistics-icon",
  );
  const domRef = useRef();
  const inView = useInView(domRef);

  return (
    <section
      ref={domRef}
      style={{
        backgroundImage: `linear-gradient(rgba(31, 40, 49, 0.95) 100%, rgba(31, 40, 49, 0.95) 100%), url(${backgroundImage.file.url})`,
      }}
      className={styles.root}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <div className={styles.headerItem}>
              <div className={styles.headerItemNumber}>
                {inView && <CountUp end={2010} duration={2} separator="" />}
              </div>
              <div className={styles.headerItemText}>{creation}</div>
            </div>
            <div className={styles.headerItem}>
              <div className={styles.headerItemNumber}>
                {inView && <CountUp end={250} duration={1.5} />}
              </div>
              <div className={styles.headerItemText}>{missions}</div>
            </div>
            <div className={styles.headerItem}>
              <div className={styles.headerItemNumber}>
                {inView && <CountUp end={100} duration={1} />}
              </div>
              <div className={styles.headerItemText}>{talents}</div>
            </div>
          </div>
          <SlideTransition thresholds={200} delay={0.3}>
            <div className={styles.text}>{statisticText}</div>
          </SlideTransition>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <SlideTransition thresholds={200} delay={0.5}>
                <div className={styles.contentItemNumber}>
                  {statisticsText[0]}
                </div>
                <div className={styles.contentItemText}>{field[0]}</div>
              </SlideTransition>
            </div>
            <div className={styles.contentItem}>
              <SlideTransition thresholds={200} delay={0.6}>
                <div className={styles.contentItemNumber}>
                  {statisticsText[1]}
                </div>
                <div className={styles.contentItemText}>
                  {field[2]} <br /> {field[3]}
                </div>
              </SlideTransition>
            </div>
            <div className={styles.contentItem}>
              <SlideTransition thresholds={200} delay={0.4}>
                <GatsbyImage
                  image={iconImage.gatsbyImageData}
                  alt="statistics"
                />
              </SlideTransition>
            </div>
            <div className={styles.contentItem}>
              <SlideTransition thresholds={200} delay={0.7}>
                <div className={styles.contentItemNumber}>
                  {statisticsText[2]}
                </div>
                <div className={styles.contentItemText}>{field[1]}</div>
              </SlideTransition>
            </div>
            <div className={styles.contentItem}>
              <SlideTransition thresholds={200} delay={0.8}>
                <div className={styles.contentItemNumber}>
                  {statisticsText[3]}
                </div>
                <div className={styles.contentItemText}>{field[4]}</div>
              </SlideTransition>
            </div>
          </div>

          <SlideTransition thresholds={200} delay={1}>
            <div className={styles.label}>
              <img
                className={styles.bpiLogo}
                src={bpiLogo.file.url}
                alt={bpiLogo.filename}
              />
              {bpiLabel}
            </div>
          </SlideTransition>
        </div>
      </div>
    </section>
  );
};

import React, { useContext } from "react";

import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { IdentityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const { hero, adventure, human } = useContext(IdentityPageContext);

  return (
    <section className={styles.root}>
      <video playsInline loop autoPlay muted className={styles.video}>
        <source type={hero.file.contentType} src={hero.file.url} />
      </video>
      <div className={styles.overlay} />
      <div className={styles.container}>
        <SlipTransition
          lines={[adventure, human]}
          color="#fff"
          important={true}
          options={{ delay: 0.4, animateY: -220 }}
        />
      </div>
    </section>
  );
};

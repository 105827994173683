import PropTypes from "prop-types";
import React, { cloneElement } from "react";

import * as styles from "./CarouselItem.module.css";

export const CarouselItem = ({ children, title, paragraph }) => {
  return (
    <div className={styles.item}>
      {cloneElement(children, { className: styles.image })}
      <div className={styles.background} />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.paragraphHead}>
        <div className={styles.paragraph}>{paragraph}</div>
      </div>
    </div>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { IdentityPageContext } from "../helpers/pagesContext";
import { IDENTITY } from "../helpers/route";
import IdentityScene from "../scenes/Identity/Identity";

export default function Identity(props) {
  return (
    <Layout currentRoute={IDENTITY} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Identité"
        description="L'identité de Zenity a été pensée par ses fondateurs Silvain et Gregory, experts du tests, rêvant d'un horizon unique coloré d'excellence et de diversité."
      />
      <IdentityPageContext.Provider value={props.data.contentfulIdentity}>
        <IdentityScene />
      </IdentityPageContext.Provider>
    </Layout>
  );
}

export const PageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulIdentity(node_locale: { eq: $locale }) {
      hero {
        file {
          url
          fileName
          contentType
        }
      }
      presentation {
        gatsbyImageData(placeholder: BLURRED)
      }
      carousel {
        id
        title
        description
        gatsbyImageData(placeholder: BLURRED)
      }
      carouselDescription {
        item
        title
      }
      offices {
        title
        file {
          url
          fileName
        }
        gatsbyImageData(placeholder: BLURRED, width: 600)
      }
      statistics {
        title
        file {
          url
          fileName
        }
        gatsbyImageData(placeholder: BLURRED)
      }
      footer {
        file {
          url
          fileName
        }
      }
      adventure
      canopy
      human
      storyLineEight
      storyLineFive
      storyLineFour
      storyLineOne
      storyLineSeven
      storyLineSix
      storyLineThree
      storyLineTwo
      ourCulture
      canopyHeadLineOne
      canopyHeadLineTwo
      canopyLineFive
      canopyLineFour
      canopyLineOne
      canopyLineSix
      canopyLineThree
      canopyLineTwo
      canopyTitleFive
      canopyTitleFour
      canopyTitleOne
      canopyTitleSeven
      canopyTitleSix
      canopyTitleThree
      canopyTitleTwo
      creation
      missions
      node_locale
      talents
      statisticText
      footerTitle
      footerButtonLabel
      field
      statisticsText
      bpiLabel
      bpiLogo {
        file {
          url
          fileName
        }
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;

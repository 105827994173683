// extracted by mini-css-extract-plugin
export var bpiLogo = "Statistics-module--bpiLogo--94458";
export var container = "Statistics-module--container--b9ff5";
export var content = "Statistics-module--content--4634d";
export var contentItem = "Statistics-module--contentItem--22e06";
export var contentItemNumber = "Statistics-module--contentItemNumber--5e340";
export var contentItemText = "Statistics-module--contentItemText--bb765";
export var header = "Statistics-module--header--6deb1";
export var headerItem = "Statistics-module--headerItem--c346d";
export var headerItemNumber = "Statistics-module--headerItemNumber--b47e5";
export var headerItemText = "Statistics-module--headerItemText--19118";
export var label = "Statistics-module--label--bbc9b";
export var root = "Statistics-module--root--91260";
export var text = "Statistics-module--text--4b096";
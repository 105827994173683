/* eslint-disable import/no-unresolved */
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { IdentityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Carousel.module.css";
import { CarouselItem } from "./components/CarouselItem";

export const Carousel = () => {
  const { carousel, carouselDescription, ourCulture } =
    useContext(IdentityPageContext);

  const carouselMapping = carousel.map((item) => ({
    ...item,
    ...carouselDescription.find((titlesItem) => titlesItem.item === item.title),
  }));

  const swiperSettings = {
    modules: [Pagination],
    grabCursor: true,
    spaceBetween: 15,
    slidesPerView: 1,
    slidesPerGroup: 1,
    breakpoints: {
      600: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1340: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1600: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
    pagination: {
      clickable: true,
      renderBullet: (_, className) => `<div class="${className}"></div>`,
    },
  };

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>
          <SlipTransition lines={[ourCulture]} />
        </div>
        <div className={styles.wrap}>
          <Swiper {...swiperSettings}>
            {carouselMapping.map((item, idx) => {
              return (
                <SwiperSlide key={item.id}>
                  <SlideTransition delay={idx * 0.1}>
                    <CarouselItem
                      title={item.title}
                      paragraph={item.description}
                    >
                      <GatsbyImage
                        image={item.gatsbyImageData}
                        alt={item.item}
                      />
                    </CarouselItem>
                  </SlideTransition>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

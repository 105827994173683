import React, { useRef } from "react";

import { EN, FR } from "../../../../../../helpers/constants";
import { useInView } from "../../../../../../helpers/useInView";
import * as styles from "./Letters.module.css";

export const Letters = ({ lang }) => {
  const domRef = useRef();
  const inView = useInView(domRef, 400);

  const svg = {
    fr: {
      L: "26 92,68 92, 68 78, 43 78, 43 3, 26 3",
      E: "M 123.548 76.7698 s -4.1616 18.144 -25.1856 18.144 S 70.7 77.178 70.7 64.447 C 70.7 50.4912 76.3592 32.9066 97.5704 32.9066 c 23.9328 0 27.36 25.0387 26.3232 33.6722 H 87.4616 s -0.4608 14.364 9.9216 15.8458 c 0 0 7.9488 1.5876 11.6064 -8.1346 Z",
      EI: "M 111.0571 56.2268 H 87.8604 s 1.5812 -11.282 11.6536 -11.282 C 109.2229 44.9448 111.0571 56.2268 111.0571 56.2268 Z",
      S: "M 151.5716 94.1384 s 16.632 0 20.7598 -15.12 c 2.6611 -9.8431 -1.4062 -16.254 -6.5167 -19.656 a 57.6223 57.6223 90 0 0 -9.8431 -4.6418 c -4.6872 -1.7842 -8.0741 -3.5986 -6.2597 -7.9682 c 1.1794 -2.8123 5.171 -3.523 8.1194 -2.4494 a 17.6753 17.6753 90 0 1 5.5037 3.5834 l 7.1064 -9.5861 S 158.678 28.9712 144.9642 33.3409 A 17.3275 17.3275 90 0 0 134.7733 43.0328 A 17.645 17.645 90 0 0 135.998 59.9521 c 3.9917 5.8061 14.8025 8.1043 18.144 10.6596 a 5.9119 5.9119 90 0 1 1.9505 6.8342 c -1.0735 2.7518 -3.2508 4.037 -6.6982 3.8556 C 142.9381 80.984 137.51 74.6185 137.51 74.6185 L 129.95 84.4314 A 25.9157 25.9157 90 0 0 151.5716 94.1384 Z",
      R: "M 224.178 92.0585 h 8.3085 V 68.757 a 65.6415 65.6415 90 0 1 0.7685 -11.194 a 13.4995 13.4995 90 0 1 3.712 -7.25 c 7.0325 -5.6985 14.6595 -0.638 14.6595 -0.638 l 1.972 -15.08 S 247.0155 31.927 240.766 34.2905 c -3.6105 1.45 -5.4955 3.277 -8.2795 6.8585 V 35.1025 H 216.232 V 92.0585 Z",
      A: "M 298.1267 86.6631 V 91.8657 H 314.1482 V 34.8597 h -15.9101 V 40.2055 A 21.0968 21.0968 90 0 0 282.4075 32.9983 C 266.02 32.9983 256.3785 47.9856 256.3785 63.0843 c 0 15.4487 9.5461 30.6747 26.2517 30.6747 a 19.6967 19.6967 90 0 0 15.4487 -7.0959 Z",
      AI: "M 299.677 63.849 c 0 -7.953 -5.4285 -16.5 -14.091 -16.5 s -13.9755 8.5635 -13.9755 16.5 s 5.2965 16.6155 13.9755 16.6155 S 299.677 71.802 299.677 63.849 Z",
      C: "M 365.299 91.8835 c 0 0 -13.1828 8.2665 -27.846 -0.1815 C 330.712 87.8245 323.2937 79.0135 322.9 64.1965 V 64.18 C 322.9 46.6075 335.2007 32.5 352.2265 32.5 c 4.4888 0 9.198 1.2045 13.104 3.729 v 16.269 c -3.4493 -3.6135 -6.8985 -5.775 -11.844 -5.775 c -9.0877 0 -14.2538 8.91 -14.2538 17.589 c 0 9.273 5.6385 16.9785 14.8365 16.9785 c 4.3628 0 8.3948 -2.2935 11.277 -5.6595 V 91.9 L 365.299 91.8835 z",
      I: "377 93, 394 93, 394 34, 377 34",
      IP: { cx: "385.2", cy: "10", r: "9.5" },
      N: "M 422.6867 64.0336 c 0 -6.9822 0.095 -17.982 9.7072 -17.982 c 9.1212 0 8.7729 8.991 8.7729 15.957 V 92.74 H 457.3665 V 57.2782 C 457.3665 43.7998 452.9642 32.8 437.6988 32.8 c -5.78 0 -11.4332 2.0088 -14.7904 7.209 h -0.2375 V 34.6954 H 406.487 V 92.74 H 422.6867 Z",
      E_: "M 520.048 76.7698 s -4.1616 18.144 -25.1856 18.144 S 467.2 77.178 467.2 64.447 C 467.2 50.4912 472.8592 32.9066 494.0704 32.9066 c 23.9328 0 27.36 25.0387 26.3232 33.6722 H 483.9616 s -0.4608 14.364 9.9216 15.8458 c 0 0 7.9488 1.5876 11.6064 -8.1346 Z",
      EI_: "M 507.5571 56.2268 H 484.3604 s 1.5812 -11.282 11.6536 -11.282 C 505.7229 44.9448 507.5571 56.2268 507.5571 56.2268 Z",
      S_: "M 548.3716 94.1384 s 16.632 0 20.7598 -15.12 c 2.6611 -9.8431 -1.4062 -16.254 -6.5167 -19.656 a 57.6223 57.6223 90 0 0 -9.8431 -4.6418 c -4.6872 -1.7842 -8.0741 -3.5986 -6.2597 -7.9682 c 1.1794 -2.8123 5.171 -3.523 8.1194 -2.4494 a 17.6753 17.6753 90 0 1 5.5037 3.5834 l 7.1064 -9.5861 S 555.478 28.9712 541.7642 33.3409 A 17.3275 17.3275 90 0 0 531.5733 43.0328 A 17.645 17.645 90 0 0 532.798 59.9521 c 3.9917 5.8061 14.8025 8.1043 18.144 10.6596 a 5.9119 5.9119 90 0 1 1.9505 6.8342 c -1.0735 2.7518 -3.2508 4.037 -6.6982 3.8556 C 539.7381 80.984 534.31 74.6185 534.31 74.6185 L 526.75 84.4314 A 25.9157 25.9157 90 0 0 548.3716 94.1384 Z",
    },
    en: {
      T: "M 20.444 6 M 20.444 94.197 V 44.124 H 14 v -15.84 h 6.444 V 6 h 18.795 v 22.284 h 11.544 v 15.84 H 39.236 v 50.073 H 20.444 z",
      H: "M 107.4271 93.7443 v 0.37 h -0.4851 h -13.2848 V 68.542 c 0 -5.8031 0.2956 -13.278 -7.475 -13.278 c -8.1631 0 -8.2624 9.1468 -8.2624 14.9499 v 23.9005 H 64.15 V 10.7075 h 13.7699 v 39.1459 h 0.1963 c 2.8519 -3.9349 7.6712 -5.6068 12.5899 -5.6068 c 12.9825 0 16.7211 9.1468 16.7211 20.3604 V 93.7443 M 107.4271 93.7443 M 107.4271 93.7443",
      E: "M 173.548 76.7698 s -4.1616 18.144 -25.1856 18.144 S 120.7 77.178 120.7 64.447 C 120.7 50.4912 126.3592 32.9066 147.5704 32.9066 c 23.9328 0 27.36 25.0387 26.3232 33.6722 H 137.4616 s -0.4608 14.364 9.9216 15.8458 c 0 0 7.9488 1.5876 11.6064 -8.1346 Z",
      R: "M 224.178 92.0585 h 8.3085 V 68.757 a 65.6415 65.6415 90 0 1 0.7685 -11.194 a 13.4995 13.4995 90 0 1 3.712 -7.25 c 7.0325 -5.6985 14.6595 -0.638 14.6595 -0.638 l 1.972 -15.08 S 247.0155 31.927 240.766 34.2905 c -3.6105 1.45 -5.4955 3.277 -8.2795 6.8585 V 35.1025 H 216.232 V 92.0585 Z",
      EI: "M 161.0571 56.2268 H 137.8604 s 1.5812 -11.282 11.6536 -11.282 C 159.2229 44.9448 161.0571 56.2268 161.0571 56.2268 Z",
      O: "M 309.6406 76.7136 c -0.0486 0.1119 -0.0997 0.2237 -0.1508 0.3332 l -0.2019 0.4281 c -4.3095 8.9789 -13.2058 14.6746 -24.7164 14.6746 C 268.1142 92.1495 257 80.5002 257 64.252 C 257 52.8849 262.4234 43.7892 271.2224 39.2997 M 272.3362 38.7671 C 275.9404 37.1377 280.0627 36.25 284.574 36.25 c 16.3527 0 27.5764 11.6493 27.5764 28.002 c 0 4.5697 -0.8804 8.7747 -2.5098 12.4615 M 271.2224 39.2997 l 0.4621 -0.231 c 0.214 -0.1046 0.4329 -0.2067 0.6518 -0.304 M 271.2224 39.2997 M 277.5431 52.024 l 0.3648 -0.287 c 1.8435 -1.3911 4.0858 -2.2326 6.6662 -2.2326 c 7.6948 0 12.3984 7.4809 12.3984 14.7501 c 0 1.5808 -0.2359 3.1859 -0.6883 4.7278 l -0.1241 0.4061 c -0.0413 0.1264 -0.0826 0.2505 -0.1241 0.3745 M 276.9716 52.5226 C 273.9024 55.3704 272.1757 59.8501 272.1757 64.252 c 0 7.0553 4.7034 14.6431 12.3984 14.6431 c 5.6787 0 9.728 -4.1319 11.462 -9.1346 M 277.5431 52.024 c -0.1946 0.1605 -0.3866 0.3259 -0.5715 0.4986 M 277.5431 52.024",
      O_: "M 369.6406 76.7136 c -0.0486 0.1119 -0.0997 0.2237 -0.1508 0.3332 l -0.2019 0.4281 c -4.3095 8.9789 -13.2058 14.6746 -24.7164 14.6746 C 328.1142 92.1495 317 80.5002 317 64.252 C 317 52.8849 322.4234 43.7892 331.2224 39.2997 M 332.3362 38.7671 C 335.9404 37.1377 340.0627 36.25 344.574 36.25 c 16.3527 0 27.5764 11.6493 27.5764 28.002 c 0 4.5697 -0.8804 8.7747 -2.5098 12.4615 M 331.2224 39.2997 l 0.4621 -0.231 c 0.214 -0.1046 0.4329 -0.2067 0.6518 -0.304 M 331.2224 39.2997 M 337.5431 52.024 l 0.3648 -0.287 c 1.8435 -1.3911 4.0858 -2.2326 6.6662 -2.2326 c 7.6948 0 12.3984 7.4809 12.3984 14.7501 c 0 1.5808 -0.2359 3.1859 -0.6883 4.7278 l -0.1241 0.4061 c -0.0413 0.1264 -0.0826 0.2505 -0.1241 0.3745 M 336.9716 52.5226 C 333.9024 55.3704 332.1757 59.8501 332.1757 64.252 c 0 7.0553 4.7034 14.6431 12.3984 14.6431 c 5.6787 0 9.728 -4.1319 11.462 -9.1346 M 337.5431 52.024 c -0.1946 0.1605 -0.3866 0.3259 -0.5715 0.4986 M 337.5431 52.024",
      T_: "M 385.444 6 M 385.444 94.197 V 44.124 H 379 v -15.84 h 6.444 V 6 h 18.795 v 22.284 h 11.544 v 15.84 H 404.236 v 50.073 H 385.444 z",
      S: "M 438.3716 94.1384 s 16.632 0 20.7598 -15.12 c 2.6611 -9.8431 -1.4062 -16.254 -6.5167 -19.656 a 57.6223 57.6223 90 0 0 -9.8431 -4.6418 c -4.6872 -1.7842 -8.0741 -3.5986 -6.2597 -7.9682 c 1.1794 -2.8123 5.171 -3.523 8.1194 -2.4494 a 17.6753 17.6753 90 0 1 5.5037 3.5834 l 7.1064 -9.5861 S 445.478 28.9712 431.7642 33.3409 A 17.3275 17.3275 90 0 0 421.5733 43.0328 A 17.645 17.645 90 0 0 422.798 59.9521 c 3.9917 5.8061 14.8025 8.1043 18.144 10.6596 a 5.9119 5.9119 90 0 1 1.9505 6.8342 c -1.0735 2.7518 -3.2508 4.037 -6.6982 3.8556 C 429.7381 80.984 424.31 74.6185 424.31 74.6185 L 416.75 84.4314 A 25.9157 25.9157 90 0 0 438.3716 94.1384 Z",
    },
  };
  const stroke = "#C0C0C0";
  const fill = "transparent";

  return (
    <div ref={domRef} className={styles.container}>
      {inView && (
        <svg viewBox="-1 -1 600 105">
          {lang === FR && (
            <>
              <polygon
                className={styles.leftRootL}
                fill={fill}
                stroke={stroke}
                points={svg.fr.L}
              />
              <polygon
                className={styles.rightRootL}
                fill={fill}
                stroke={stroke}
                points={svg.fr.L}
              />
              <path
                className={styles.leftRootE}
                fill={fill}
                stroke={stroke}
                d={svg.fr.E}
              />
              <path
                className={styles.rightRootE}
                fill={fill}
                stroke={stroke}
                d={svg.fr.E}
              />
              <path
                className={styles.leftRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.EI}
              />
              <path
                className={styles.rightRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.EI}
              />
              <path
                className={styles.leftRootS}
                fill={fill}
                stroke={stroke}
                d={svg.fr.S}
              />
              <path
                className={styles.rightRootS}
                fill={fill}
                stroke={stroke}
                d={svg.fr.S}
              />
              <path
                className={styles.leftRootR}
                fill={fill}
                stroke={stroke}
                d={svg.fr.R}
              />
              <path
                className={styles.rightRootR}
                fill={fill}
                stroke={stroke}
                d={svg.fr.R}
              />
              <path
                className={styles.leftRootA}
                fill={fill}
                stroke={stroke}
                d={svg.fr.A}
              />
              <path
                className={styles.rightRootA}
                fill={fill}
                stroke={stroke}
                d={svg.fr.A}
              />
              <path
                className={styles.rightRootAI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.AI}
              />
              <path
                className={styles.leftRootAI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.AI}
              />
              <path
                className={styles.rightRootC}
                fill={fill}
                stroke={stroke}
                d={svg.fr.C}
              />
              <path
                className={styles.leftRootC}
                fill={fill}
                stroke={stroke}
                d={svg.fr.C}
              />
              <polygon
                className={styles.rightRootI}
                fill={fill}
                stroke={stroke}
                points={svg.fr.I}
              />
              <polygon
                className={styles.leftRootI}
                fill={fill}
                stroke={stroke}
                points={svg.fr.I}
              />
              <circle
                className={styles.circleRootI}
                cx={svg.fr.IP.cx}
                cy={svg.fr.IP.cy}
                r={svg.fr.IP.r}
                stroke={stroke}
                fill={fill}
              />
              <path
                className={styles.leftRootN}
                fill={fill}
                stroke={stroke}
                d={svg.fr.N}
              />
              <path
                className={styles.rightRootN}
                fill={fill}
                stroke={stroke}
                d={svg.fr.N}
              />
              <path
                className={styles.leftRootE}
                fill={fill}
                stroke={stroke}
                d={svg.fr.E_}
              />
              <path
                className={styles.rightRootE}
                fill={fill}
                stroke={stroke}
                d={svg.fr.E_}
              />
              <path
                className={styles.leftRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.EI_}
              />
              <path
                className={styles.rightRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.fr.EI_}
              />
              <path
                className={styles.leftRootS}
                fill={fill}
                stroke={stroke}
                d={svg.fr.S_}
              />
              <path
                className={styles.rightRootS}
                fill={fill}
                stroke={stroke}
                d={svg.fr.S_}
              />
            </>
          )}
          {lang === EN && (
            <>
              <path
                className={styles.leftRootT}
                fill={fill}
                stroke={stroke}
                d={svg.en.T}
              />
              <path
                className={styles.rightRootT}
                fill={fill}
                stroke={stroke}
                d={svg.en.T}
              />
              <path
                className={styles.leftRootH}
                fill={fill}
                stroke={stroke}
                d={svg.en.H}
              />
              <path
                className={styles.rightRootH}
                fill={fill}
                stroke={stroke}
                d={svg.en.H}
              />
              <path
                className={styles.leftRootE}
                fill={fill}
                stroke={stroke}
                d={svg.en.E}
              />
              <path
                className={styles.rightRootE}
                fill={fill}
                stroke={stroke}
                d={svg.en.E}
              />
              <path
                className={styles.leftRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.en.EI}
              />
              <path
                className={styles.rightRootEI}
                fill={fill}
                stroke={stroke}
                d={svg.en.EI}
              />
              <path
                className={styles.leftRootR}
                fill={fill}
                stroke={stroke}
                d={svg.en.R}
              />
              <path
                className={styles.rightRootR}
                fill={fill}
                stroke={stroke}
                d={svg.en.R}
              />
              <path
                className={styles.leftRootO}
                fill={fill}
                stroke={stroke}
                d={svg.en.O}
              />
              <path
                className={styles.rightRootO}
                fill={fill}
                stroke={stroke}
                d={svg.en.O}
              />
              <path
                className={styles.leftRootO}
                fill={fill}
                stroke={stroke}
                d={svg.en.O_}
              />
              <path
                className={styles.rightRootO}
                fill={fill}
                stroke={stroke}
                d={svg.en.O_}
              />
              <path
                className={styles.leftRootT}
                fill={fill}
                stroke={stroke}
                d={svg.en.T_}
              />
              <path
                className={styles.rightRootT}
                fill={fill}
                stroke={stroke}
                d={svg.en.T_}
              />
              <path
                className={styles.leftRootS}
                fill={fill}
                stroke={stroke}
                d={svg.en.S}
              />
              <path
                className={styles.rightRootS}
                fill={fill}
                stroke={stroke}
                d={svg.en.S}
              />
            </>
          )}
        </svg>
      )}
    </div>
  );
};

import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { IdentityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Offices.module.css";

export const Offices = () => {
  const {
    offices,
    canopy,
    canopyHeadLineOne,
    canopyHeadLineTwo,
    canopyLineFive,
    canopyLineFour,
    canopyLineOne,
    canopyLineSix,
    canopyLineThree,
    canopyLineTwo,
    canopyTitleFive,
    canopyTitleFour,
    canopyTitleOne,
    canopyTitleSeven,
    canopyTitleSix,
    canopyTitleThree,
    canopyTitleTwo,
  } = useContext(IdentityPageContext);

  const backgroundImage = offices.find(
    (item) => item.title === "identity-office-background"
  );
  const officeImage = offices.find(
    (item) => item.title === "identity-office-main"
  );

  return (
    <section
      style={{ backgroundImage: `url(${backgroundImage.file.url})` }}
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <SlipTransition lines={[canopy]} />
        </div>
        <div className={styles.content}>
          <div className={styles.image}>
            <SlideTransition delay={0.2}>
              <GatsbyImage image={officeImage.gatsbyImageData} alt="offices" />
            </SlideTransition>
          </div>
          <div className={styles.presentation}>
            <SlideTransition delay={0.5}>
              <div className={styles.text}>
                <div>
                  <p>{canopyHeadLineOne}</p>
                  <p>{canopyHeadLineTwo}</p>
                </div>
                <div>
                  <span>{canopyTitleOne}</span>
                  <br />
                  {canopyLineOne}
                </div>
                <div>
                  <span>{canopyTitleTwo}</span>
                  <br />
                  {canopyLineTwo}
                </div>
                <div>
                  <span>{canopyTitleThree}</span>
                  <br />
                  {canopyLineThree}
                </div>
                <div>
                  <span>{canopyTitleFour}</span>
                  <br />
                  {canopyLineFour}
                </div>
                <div>
                  <span>{canopyTitleFive}</span>
                  <br />
                  {canopyLineFive}
                </div>
                <div>
                  <span>{canopyTitleSix}</span>
                  <br />
                  {canopyLineSix}
                </div>
                <div>
                  <span>{canopyTitleSeven}</span>
                </div>
              </div>
            </SlideTransition>
          </div>
        </div>
      </div>
    </section>
  );
};

import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { IdentityPageContext } from "../../../../helpers/pagesContext";
import { Letters } from "./components/Letters/Letters";
import { Story } from "./components/Story/Story";
import * as styles from "./Presentation.module.css";

export const Presentation = () => {
  const { presentation, node_locale } = useContext(IdentityPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <Story />
        <div className={styles.image}>
          <GatsbyImage image={presentation.gatsbyImageData} alt="Les patrons" />
        </div>
      </div>
      <Letters lang={node_locale} />
    </section>
  );
};

import React, { useContext } from "react";

import { SlideTransition } from "../../../../../../components/SlideTransition/SlideTransition";
import { IdentityPageContext } from "../../../../../../helpers/pagesContext";
import * as styles from "./Story.module.css";

export const Story = () => {
  const {
    storyLineOne,
    storyLineTwo,
    storyLineThree,
    storyLineFour,
    storyLineFive,
    storyLineSix,
    storyLineSeven,
    storyLineEight,
  } = useContext(IdentityPageContext);

  return (
    <div className={styles.content}>
      <SlideTransition thresholds={100}>
        {storyLineOne}
        <br />
        {storyLineTwo}
        <br />
        {storyLineThree}
        <br />
        {storyLineFour}
        <br />
        {storyLineFive}
        <span className={styles.colored}>{storyLineSix} </span>
        {storyLineSeven}
        <span className={styles.colored}> {storyLineEight} </span>
      </SlideTransition>
    </div>
  );
};
